import React from "react";
import Layout from "../components/shared/layout";
import solidBook from "../images/book/book-logo.png";
import ResourcesComponent from '../components/home/components/Resources'
import prose from "../assets/prose";

const resourceItems = [
  {
    name:
      "SOLID: An Introductory Ontology of the World of Software Design, Architecture & Testing (with TypeScript / Node.js) Handbook",
    description: `Gain clarity. Uncover the unknown unknowns & get up to speed on the main topics of software design, architecture & testing from over the last 40 years.`,
    url: "https://solidbook.io",
    img: solidBook,
    contentType: "Digital book",
  },
  // {
  //   name: "An Introduction to The 5 Phases of Craftship",
  //   description: `Beat burnout, imposter syndrome & information overload. Start making progress. Know where you are (& how to get to the next phase).`,
  //   url: "https://www.essentialist.dev/offers/hN4GMJcp",
  //   img: 'https://kajabi-storefronts-production.kajabi-cdn.com/kajabi-storefronts-production/file-uploads/site/2147638698/images/bc6a133-e82b-bdc-0d4b-35b273657cd6_76c9866e-be5b-49d3-a083-8acb177ebfdc.png',
  //   contentType: "Free course",
  // },
];

export default function Resources() {
  return (
    <Layout
      title="Resources"
      newsletterContent={
        <>
          <p>Helpful resources</p>
          <h3>
            Not subscribed? Get the latest newsletters straight to your inbox.
            Learn to write scalable, testable software.
          </h3>
          {prose.main.join}
        </>
      }
      seo={{
        title: "Resources",
        keywords: ["nodejs", "javascript", "typescript", "resources"],
      }}
      component={
        <>
          <p>
            Go from coder to systems thinker.
          </p>
        </>
      }
    >
      <ResourcesComponent displayHeader={false}/>
    </Layout>
  );
}
